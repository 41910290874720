@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';

.pointer {
    cursor: pointer;
}

nb-list.selection {
    cursor: pointer;
    :hover {
        background-color: $gray-200;
    }
}

nb-menu {
    .menu-group,
    .menu-item a {

        .menu-title {
            width: 170px;
        }

        nb-badge {
            margin-left: 10px;
        }
    }
}